const getAppUrl = app => {
  return `${app.slug}`;
}

const getTopicUrl = topic => {
  return `/${topic?.app?.slug}/${topic.slug}`;
}

const getArticleUrl = article => {
  const app = article.topic.app;
  const useAppSlug = (process.env.GATSBY_USE_APP_SLUG || 'TRUE').toUpperCase() === "TRUE"
  return useAppSlug ? `/${app.slug}/answers/${getNakedId(article.id)}` : `/answers/${getNakedId(article.id)}` ;
}

const isSiteApp = (app, apps) => {
  return apps.includes('*') || apps.includes(app)
}

const getNakedId = (id) => {
  const result = id.match(/Dato[A-Za-z]*-(.*)-en/)
  return result? result[1] : ''
}

const isSiteTopic = (topic, { apps, excludedTopics } ) => {
  return !(excludedTopics.includes(topic.slug) || excludedTopics.includes(getNakedId(topic.id)))
    && (apps.includes('*') || apps.includes(topic.app.slug))
}

const isSiteArticle = (article, { apps, excludedTopics, excludedArticles } ) => {
  return !(excludedArticles.includes(getNakedId(article.id)) || excludedArticles.includes(article.slug))
    && !(excludedTopics.includes(article.topic.slug) || excludedTopics.includes(getNakedId(article.topic.id)))
    && (apps.includes('*') || apps.includes(article.topic.app.slug))
}

module.exports = {
  getAppUrl,
  getTopicUrl,
  getArticleUrl,
  isSiteApp,
  isSiteTopic,
  isSiteArticle,
}

