import React from 'react';
import { graphql, Link } from 'gatsby';
import { Flex, Grid, Box, Text } from '@theme-ui/components';
import Layout from '../components/organisms/Layout';
import Container from '../components/organisms/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { SearchInputBox } from '../components/organisms/search';
import styled from '@emotion/styled';
import { isSiteApp } from '../utils';

const StyledLink = styled(Link)`
  font-size: ${(props) => props.theme.fontSizes[2]};
  color: ${(props) => props.theme.colors.gray[6]};
  font-weight: normal;
  line-height: 1.8rem;
  text-decoration: none;
  margin: 20px;
  width: 250px;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.topicBg};
    box-shadow: 2px 2px 8px 0px ${(props) => props.theme.colors.topicShadow};
  }
`;

const SearchBox = ({ filterApps }) => (
  <Box px={3}>
    <SearchInputBox
      filterApps={filterApps}
      styles={{
        width: '100%',
        'input.inputBox': {
          width: '100%',
          border: '2px solid',
          borderColor: 'primary'
        },
      }}
    />
  </Box>
);

const App = ({ app }) => {
  return (
    <StyledLink to={`/${app.slug}`}>
      <Flex
        dir="column"
        sx={{
          m: 0,
          p: 3,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'topicBorder',
          height: 120,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Flex dir="row" sx={{ mr: 3, p: 0, border: '0px solid red' }}>
          <Text>{app.title}</Text>
        </Flex>
        <FontAwesomeIcon icon={faChevronRight} />
      </Flex>
    </StyledLink>
  );
};

// markup
const IndexPage = ({ data: { apps, site } }) => {
  const {
    siteMetadata: { apps: siteApps },
  } = site;

  const appNodes = apps.nodes.filter((app) => isSiteApp(app.slug, siteApps));

  const filterApps = [];
  appNodes.map(({ slug }) => filterApps.push(slug));
  const search = { showSearch: false };

  return (
    <Layout search={search}>
      <Container sx={{ mt: 2 }}>
        <SearchBox filterApps={filterApps} />
        <Grid gap={15} columns={3}>
          {appNodes.map((app) => (
            <App key={app.id} app={app} />
          ))}
        </Grid>
      </Container>
    </Layout>
  );
};

export const appQuery = graphql`
  query getApp {
    site {
      siteMetadata {
        apps
      }
    }
    apps: allDatoCmsApp {
      nodes {
        id
        title
        slug
      }
    }
  }
`;

export default IndexPage;
